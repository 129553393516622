import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import { globalHistory } from "@reach/router";

import Template from "../components/Template";

import * as styles from "./blog.module.scss";

const BlogPage = ({ data, location }) => {
  const pageLang = !globalHistory.location.pathname.includes("/fr")
    ? "en"
    : "fr";

  const show = (lang) => {
    if (lang === "en" && pageLang === "en") return { display: "block" };
    else if (lang === "fr" && pageLang === "fr") return { display: "block" };
    else return { display: "none" };
  };

  const blogEntries = data.allMarkdownRemark.edges.map((node) => node.node);

  return (
    <Template location={location}>
      <Helmet>
        <title>{`Charih / ${pageLang === "en" ? "Blog" : "Blog"}`}</title>
      </Helmet>
      <div className={styles.container}>
        <h1>Blog</h1>
        <div className={styles.blogEntryList}>
          {blogEntries
            .filter((entry) => entry.frontmatter.ready)
            .map((entry) => (
              <div>
                <Link to={entry.frontmatter.slug}>
                  {entry.frontmatter.title} ({entry.frontmatter.date})
                </Link>
              </div>
            ))}
        </div>
      </div>
    </Template>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            date
            lang
            ready
          }
        }
      }
    }
  }
`;

export default BlogPage;
